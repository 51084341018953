<template>
  <div class="home">
    <!-- 视频 -->
    <div class="home_title">
      <span>四川民族地区风情展示</span>
      <div class="more_video">
        查看更多
        <div class="sph_qrcode">
          <span>官方微信视频号</span>
          <img src="@/assets/img/wx_sph.jpg" />
        </div>
      </div>
    </div>
    <div class="video_content">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>
    <!--tab-->
    <div
      class="home_title feature_title"
      id="anchor"
      style="margin-bottom: 1.2rem"
    >
      民族特色博览
    </div>
    <div class="feature" v-if="featureList.length > 0">
      <div
        :class="['feature_list', index === activeFeature ? 'active' : '']"
        v-for="(item, index) in featureList"
        :key="index"
        @click="clickFeature(index)"
      >
        <div class="icon"><img :src="item.src" alt="" /></div>
        <div class="name">{{ item.name }}<i></i></div>
      </div>
    </div>
    <br />
    <div class="feature_specific" v-if="featureList.length > 0">
      <div
        class="card_list"
        v-for="(item, index) in featureList[activeFeature].featureTravelList"
        :key="index"
      >
        <div class="title">
          <div class="name">{{ item.name }}</div>
          <span class="more" @click="showMore(item.name, item)">查看更多</span>
        </div>
        <div class="intro">{{ item.intro }}</div>
        <div v-if="item.banner.length > 0" class="banner">
          <el-carousel
            type="card"
            :autoplay="false"
            height="8rem"
            @change="
              (pre, next) => {
                change(pre, next, item);
              }
            "
          >
            <el-carousel-item v-for="(n, i) in item.banner" :key="i">
              <!-- <div class="zoomImage" :style="{backgroundImage:'url('+ item.banner[i].img +')'}" @click="goDetail(item.banner[i].id)"></div> -->
              <div class="zoomImage">
                <img
                  :src="item.banner[i].img"
                  @click="goDetail(item.banner[i].id)"
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          class="banner_text"
          @click="goDetail(item.banner[item.activeBanner].id)"
          v-if="item.banner[item.activeBanner]"
        >
          <div class="b_name">
            {{
              item.banner[item.activeBanner].name
                | showText(
                  item.banner[item.activeBanner].name,
                  item.activeBanner
                )
            }}
          </div>
          <div class="b_intro">{{ item.banner[item.activeBanner].intro }}</div>
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <!-- <div class="home_title">
      少数民族特色村寨数字化保护系统
      <img
        @click="jumpIndex()"
        src="@/assets/img/see_more.png"
        style="width: 1.5rem; position: absolute; cursor: pointer"
        alt=""
      />
    </div> -->
    <!-- <div class="home_title home_title2">走进丹巴县·基卡依村</div> -->
    <!-- <div class="home_title home_title2"></div> -->
    <div class="map_content" @click="jump()">
      <!-- <div id="map"></div> -->
      <!-- <img src="@/assets/img/emvdps.jpg" alt="" /> -->
      <!-- <a class="icon">
        <img src="@/assets/img/home_map_icon.gif" alt="" />
      </a> -->
      <iframe src="https://scmzbl.com/emvdps/public"></iframe>
      <div class="marker" @click="jump()"></div>
    </div>
    <div class="home_title home_title2"></div>
    <el-backtop style="width: 1rem" :right="10" :bottom="50">
      <div
        style="
           {
            height: 100%;
            width: 100%;
          }
        "
      >
        <img style="width: 100%; height: 100%" src="@/assets/img/toTop.png" />
        <div style="text-align: center" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import AMap from 'AMap'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  name: 'Home',
  components: {
    videoPlayer
  },
  data() {
    return {
      playerOptions: {
        // playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            // src: 'http://mp4.vjshi.com/2020-12-26/fd0268b356cc4ef539f58e15337da304.mp4' // url地址
            src: 'http://file.bzjkpt.com/sichuanminzu.mp4'
          }
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      },
      // 民族特色
      activeFeature: 0,
      featureList: [
        // { name: '特色文化', src: require('@/assets/img/home_icon_culture.png'), type: 'culture' },
        // { name: '特色村镇', src: require('@/assets/img/home_icon_town.png'), type: 'town' },
        // { name: '特色产品', src: require('@/assets/img/home_icon_product.png'), type: 'product' },
        // { name: '特色旅游', src: require('@/assets/img/home_icon_travel.png'), type: 'travel' }
      ],
      featureCcultureList: [],
      featureTownList: [],
      featureProductList: [],
      featureTravelList: []
    }
  },
  computed: {
    // currFeatureList() {
    //   let list = []
    //   switch(this.activeFeature) {
    //     case '特色文化':
    //       list = this.featureCcultureList
    //       console.log('421--->', list)
    //       break
    //     case '特色村镇':
    //       list = this.featureTownList
    //       break
    //     case '特色产品':
    //       list = this.featureProductList
    //       break
    //     case '特色旅游':
    //       list = this.featureTravelList
    //       break
    //   }
    //   return list
    // }
  },
  created() {
    // this.getBannerList()
    this.getCategoryItem()
  },
  mounted() {
    console.log('width--->', document.body.clientWidth)
    this.addVisits()
    this.getVideo()
    this.gaodeMap()
    this.$EventBus.$on('scrollToAnchor', (anchor) => {
      document.querySelector(anchor).scrollIntoView()
    })
  },
  methods: {
    // 获取首页视频
    getVideo() {
      this.$axios.get('/api/web_index/getVideoModel').then((res) => {
        // console.log('video--->', res)
        this.playerOptions.sources[0].src = res.data.url
      })
    },
    // 记录少数民族地图点击
    addClick() {
      this.$axios.post('/api/web_index/click').then((res) => {
        console.log('res--->', res)
      })
    },
    // 记录访问了
    addVisits() {
      this.$axios.post('/api/web_index/addVisits').then((res) => {
        console.log('res--->', res)
      })
    },
    // 获取获取轮播图列表
    // getBannerList() {
    //   this.request.get('getBannerList').then((res) => {
    //     console.log('447--->', res)
    //   })
    // },
    // 获取民族特色博览内容
    getCategoryItem() {
      this.request.get('getCategoryItem').then((res) => {
        // console.log('453--->', res)
        this.featureList = res.splice(0, 5)
        // console.log(this.featureList)
        // console.log(this.activeFeature)
        // console.log(this.featureList[this.activeFeature].featureTravelList)
      })
    },
    clickFeature(index) {
      // console.log('180--->', index)
      this.activeFeature = index
    },
    // 查看更多
    showMore(val, item) {
      // console.log('480--->', item)
      // if (val === '特色农牧产品') {
      //   this.$router.push({ path: '/farmProducts' })
      //   // } else if (val === '非遗文化展示') {
      //   //   this.$router.push({ path: '/culture' })
      // } else {
      //   this.$router.push({ path: '/feature', query: { categoryId: item.id } })
      // }

      this.$router.push({ path: '/feature', query: { categoryId: item.id } })
    },
    gaodeMap() {
      const map = new AMap.Map('map', {
        center: [116.397428, 39.90923], // [纬度，经度]
        resizeEnable: true,
        zoom: 10
      })
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
        map.addControl(new AMap.ToolBar())
        map.addControl(new AMap.Scale())
      })
    },
    // 点击地图跳转链接
    jump() {
      this.addClick()
      window.open('/emvdps/public/map')
    },
    // 点击更多跳转至首页
    jumpIndex() {
      this.addClick()
      window.open('/emvdps/public/map')
    },
    // 跳转到详情页
    goDetail(val) {
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    change(pre, next, item) {
      item.activeBanner = pre
      // console.log('215--->', item.activeBanner)
      switch (this.activeFeature) {
        case '特色文化':
          this.featureCcultureList[0] = item
          break
        case '特色村寨':
          this.featureTownList[0] = item
          break
        case '特色产品':
          this.featureProductList[0] = item
          break
        case '特色旅游':
          this.featureTravelList[0] = item
          break
      }
    }
  },
  filters: {
    showText(val, index) {
      // console.log('513-->', val)
      // console.log('index-->', index)
      return val
    }
  }
}
</script>

<style lang="scss">
@import 'index.scss';
.home {
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
}
.el-carousel {
  overflow-y: hidden;
}
// .el-carousel {
//   .el-carousel__item--card {
//     width: auto !important;
//   }
//   .el-carousel__item {
//     width: auto !important;
//   }
//   .el-carousel__item--card.is-active {
//     z-index: 2;
//     position: absolute;
//     left: 50%;
//     transform: translate(-50%, 0px) !important;
//   }
// }
.zoomImage {
  width: 100%;
  height: 100%;
  // background-size: cover;
}
// .zoomImage {
//   width: 100%;
//   height: 0;
//   padding-bottom: 100%;
//   overflow: hidden;
//   background-position: center center;
//   background-repeat: no-repeat;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   background-size: cover;
// }
@media screen and (max-width: 600px) {
  .home .feature_list .icon {
    width: 0.6rem !important;
    height: 0.6rem !important;
  }
  .home .feature_list .name {
    font-size: 0.16rem;
    margin-top: 0.2rem;
  }
  .home .feature_specific .card_list .title .name {
    font-size: 0.4rem !important;
  }
}
</style>
